import { CellId, StaticCellId, notEmpty } from "@hex/common";
import { useCallback } from "react";
import { ScrollBehavior } from "scroll-into-view-if-needed/typings/types.js";

import {
  useAirlockBlockCellIds,
  useAirlockCellIds,
} from "../../hex-version-multiplayer/state-hooks/blockCellHooks.js";
import { useCellsSelector } from "../../hex-version-multiplayer/state-hooks/cellsStateHooks.js";
import { useDispatch, useSelector, useStore } from "../../redux/hooks";
import {
  CellMP,
  hexVersionMPActions,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import {
  addSelectedCellIds,
  cellSelectionSelectors,
  closeDraftAirlock,
  removeSelectedCellIds,
  setSelectedCellIds,
} from "../../redux/slices/logicViewSlice";
import { evtModKey } from "../../util/Keys";
import { useProjectContext } from "../../util/projectContext";

import { CellScrollAPIOptions, useScrollToCell } from "./useScrollToCell.js";

export function useSelectedCellIds(): Set<CellId> {
  return useSelector(cellSelectionSelectors.selectSelectedCellIdSet);
}

export function useGetSelectedCellIds(): () => Set<CellId> {
  const store = useStore();
  return useCallback(
    () => cellSelectionSelectors.selectSelectedCellIdSet(store.getState()),
    [store],
  );
}

export function useIsCellSelected(cellId: CellId | null): boolean {
  return useSelector(
    (state) =>
      cellId != null &&
      cellSelectionSelectors.selectIsCellSelected(state, cellId),
  );
}

export function useIsCellMultiselected(cellId: CellId | null): boolean {
  return useSelector(
    (state) =>
      cellId != null &&
      cellSelectionSelectors.selectIsCellMultiselected(state, cellId),
  );
}

export function useNumberOfSelectedCells(): number {
  return useSelector((state) =>
    cellSelectionSelectors.selectNumberOfCellsSelected(state),
  );
}

export function useSortedSelectedCellsGetter(): () => readonly CellMP[] {
  const store = useStore();
  const { hexVersionId } = useProjectContext();

  return useCallback(() => {
    return cellSelectionSelectors.selectSortedSelectedCells(
      store.getState(),
      hexVersionId,
    );
  }, [hexVersionId, store]);
}

export function useFirstSelectedCellIdGetter(): () => CellId | null {
  const getSortedSelectedCells = useSortedSelectedCellsGetter();

  return useCallback(() => {
    const sortedSelectedCells = getSortedSelectedCells();
    return sortedSelectedCells[0]?.id ?? null;
  }, [getSortedSelectedCells]);
}

export function useLastSelectedCellIdGetter(): () => CellId | null {
  const getSortedSelectedCells = useSortedSelectedCellsGetter();

  return useCallback(() => {
    const sortedSelectedCells = getSortedSelectedCells();
    return sortedSelectedCells[sortedSelectedCells.length - 1]?.id ?? null;
  }, [getSortedSelectedCells]);
}

interface SelectCellOptions {
  /**
   * Whether or not to replace the existing selection state or just
   * add to it.
   *
   * @default true
   */
  replace?: boolean;
  /**
   * Which part of the new cell to scroll to.
   * Defaults to "source".
   * If multiple are selected, this scrolls to the
   * specified part of the first cell.
   *
   * @default "source"
   */
  scrollTarget?:
    | "source"
    | "output"
    | { type: "lineNumber"; lineNumber: number }
    | "none";

  scrollBehavior?: ScrollBehavior;
  waitForSizeChangesBeforeScrolling?: boolean;
  /**
   * This gets passed through to the closeDraftAirlock action. If true, then
   * this will disable the closing animation for the action bar.
   */
  disableCloseAnimation?: boolean;
}

export interface UseSelectCellResult {
  selectCells: (
    cellIds?: CellId | CellId[],
    options?: SelectCellOptions,
  ) => void;
  deselectCells: (cellIds: CellId | CellId[]) => void;
  selectCellsByStaticId: (
    staticCellIds?: StaticCellId | StaticCellId[],
    options?: SelectCellOptions,
  ) => void;
  selectCellRange: (
    startingCellId: CellId,
    endingCellId: CellId,
    options?: SelectCellOptions,
  ) => void;
  selectFollowingCell: (cellId: CellId, options?: SelectCellOptions) => void;
  selectPreviousCell: (cellId: CellId, options?: SelectCellOptions) => void;
  addNextCellToSelectedRange: () => void;
  addPreviousCellToSelectedRange: () => void;
  scrollToCell: (cellId: CellId, options: CellScrollAPIOptions) => void;
}

// eslint-disable-next-line max-lines-per-function
export function useSelectCell(): UseSelectCellResult {
  const { hexVersionId } = useProjectContext();
  const store = useStore();
  const dispatch = useDispatch();
  const scrollToCell = useScrollToCell();

  const selectCells: UseSelectCellResult["selectCells"] = useCallback(
    (
      cellIds_ = [],
      {
        disableCloseAnimation = false,
        replace = true,
        scrollBehavior = "smooth",
        scrollTarget = "source",
        waitForSizeChangesBeforeScrolling = false,
      } = {},
    ) => {
      const cellIds = Array.isArray(cellIds_) ? cellIds_ : [cellIds_];

      if (cellIds.length === 0 && replace) {
        dispatch(
          hexVersionMPActions.setActiveMagicCell({
            hexVersionId,
            data: null,
          }),
        );
      }
      // on single cell selection we should close draft airlocks, and set magic
      // to be in edit mode for a single cell. This does not set the prompt
      // bar to be open though which depends on if its already open
      if (cellIds.length === 1 && replace) {
        dispatch(closeDraftAirlock({ disableCloseAnimation }));
        dispatch(
          hexVersionMPActions.setActiveMagicCell({
            hexVersionId,
            data: cellIds[0],
          }),
        );
        dispatch(
          hexVersionMPActions.setMagicEditMode({
            hexVersionId,
            data: true,
          }),
        );
      }

      if (replace) {
        dispatch(setSelectedCellIds(cellIds));
      } else {
        dispatch(addSelectedCellIds(cellIds));
      }

      if (scrollTarget !== "none" && cellIds.length === 1) {
        scrollToCell(cellIds[0], {
          scrollTarget,
          scrollBehavior,
          waitForSizeChanges: waitForSizeChangesBeforeScrolling,
        });
      }
    },
    [dispatch, hexVersionId, scrollToCell],
  );

  const deselectCells: UseSelectCellResult["deselectCells"] = useCallback(
    (cellIds) => {
      dispatch(
        removeSelectedCellIds(Array.isArray(cellIds) ? cellIds : [cellIds]),
      );
    },
    [dispatch],
  );

  const selectCellsByStaticId: UseSelectCellResult["selectCellsByStaticId"] =
    useCallback(
      (staticCellIds_ = [], options) => {
        const staticCellIds = Array.isArray(staticCellIds_)
          ? staticCellIds_
          : [staticCellIds_];

        const cellIds = staticCellIds
          .map(
            (staticCellId) =>
              store.getState().hexVersionMP[hexVersionId]?.staticCellIdToCellId[
                staticCellId
              ],
          )
          .filter(notEmpty);

        selectCells(cellIds, options);
      },
      [hexVersionId, selectCells, store],
    );

  const selectCellRange: UseSelectCellResult["selectCellRange"] = useCallback(
    (startingCellId, endingCellId, options) => {
      const cells = hexVersionMPSelectors
        .getCellSelectors(hexVersionId)
        .selectFlattenedSortedCellsWithoutSQLBlockChildrenV2(store.getState());

      const startingIndex = cells.findIndex((c) => c.id === startingCellId);
      const endingIndex = cells.findIndex((c) => c.id === endingCellId);

      const startingSliceIndex = Math.min(startingIndex, endingIndex);
      const endingSliceIndex = Math.max(startingIndex, endingIndex);

      const cellsInRange = cells.slice(
        startingSliceIndex,
        endingSliceIndex + 1,
      );

      const shouldReverse = startingIndex > endingIndex;

      if (shouldReverse) {
        cellsInRange.reverse();
      }

      return selectCells(
        cellsInRange.map((cell) => cell.id),
        options,
      );
    },
    [hexVersionId, selectCells, store],
  );

  const selectFollowingCell: UseSelectCellResult["selectFollowingCell"] =
    useCallback(
      (cellId, options) => {
        const cells = hexVersionMPSelectors
          .getCellSelectors(hexVersionId)
          .selectFlattenedSortedCellsWithoutSQLBlockChildrenV2(
            store.getState(),
          );

        if (cells.length === 0 || cellId == null) {
          return selectCells(undefined, options);
        }
        const currentIndex = cells.findIndex((c) => c.id === cellId);
        const nextIndex =
          currentIndex === -1
            ? 0
            : Math.min(cells.length - 1, currentIndex + 1);

        const nextCell: CellMP | undefined = cells[nextIndex];

        if (!nextCell) {
          return;
        }
        return selectCells(nextCell.id, options);
      },
      [hexVersionId, selectCells, store],
    );

  const selectPreviousCell: UseSelectCellResult["selectPreviousCell"] =
    useCallback(
      (cellId, options) => {
        const cells = hexVersionMPSelectors
          .getCellSelectors(hexVersionId)
          .selectFlattenedSortedCellsWithoutSQLBlockChildrenV2(
            store.getState(),
          );
        if (cells.length === 0 || cellId == null) {
          return selectCells(undefined, options);
        }

        const currentIndex = cells.findIndex((c) => c.id === cellId);
        const prevIndex =
          currentIndex === -1 ? 0 : Math.max(0, currentIndex - 1);

        const prevCell: CellMP | undefined = cells[prevIndex];

        if (!prevCell) {
          return;
        }
        return selectCells(prevCell.id, options);
      },
      [hexVersionId, selectCells, store],
    );

  const addNextCellToSelectedRange: UseSelectCellResult["addNextCellToSelectedRange"] =
    useCallback(() => {
      const lastSelectedCellId =
        store.getState().logicView.mostRecentlySelectedCellId;
      if (lastSelectedCellId == null) {
        return;
      }
      selectFollowingCell(lastSelectedCellId, { replace: false });
    }, [selectFollowingCell, store]);

  const addPreviousCellToSelectedRange: UseSelectCellResult["addPreviousCellToSelectedRange"] =
    useCallback(() => {
      const lastSelectedCellId =
        store.getState().logicView.mostRecentlySelectedCellId;
      if (lastSelectedCellId == null) {
        return;
      }
      selectPreviousCell(lastSelectedCellId, { replace: false });
    }, [selectPreviousCell, store]);

  return {
    selectCells,
    deselectCells,
    selectCellsByStaticId,
    selectCellRange,
    selectFollowingCell,
    selectPreviousCell,
    addNextCellToSelectedRange,
    addPreviousCellToSelectedRange,
    scrollToCell,
  };
}

export function useSelectCellClickHandler({
  cellId,
}: {
  cellId: CellId;
}): React.MouseEventHandler {
  const store = useStore();
  const getSelectedCellIds = useGetSelectedCellIds();
  const { deselectCells, selectCellRange, selectCells } = useSelectCell();

  return useCallback(
    (evt) => {
      const { mostRecentlySelectedCellId } = store.getState().logicView;
      const selectedCellIds = getSelectedCellIds();

      const holdingShiftEvt = evt.shiftKey;
      const holdingModEvt = evtModKey(evt);

      if (holdingShiftEvt && mostRecentlySelectedCellId != null) {
        selectCellRange(mostRecentlySelectedCellId, cellId, {
          replace: false,
        });
      } else if (holdingModEvt) {
        if (selectedCellIds.has(cellId)) {
          deselectCells(cellId);
        } else {
          selectCells(cellId, { replace: false });
        }
      } else {
        selectCells(cellId);
      }
    },
    [
      cellId,
      deselectCells,
      getSelectedCellIds,
      selectCellRange,
      selectCells,
      store,
    ],
  );
}

export function useSelectionIncludesAirlockCells(): boolean {
  const cellsById = useCellsSelector({
    selector: (cellState) => cellState,
  });
  const getSelectedCellIds = useGetSelectedCellIds();
  const airlockCellIds = useAirlockCellIds();
  const airlockBlockCellIds = useAirlockBlockCellIds();

  return Array.from(getSelectedCellIds()).some((id) => {
    const cell = cellsById[id];
    if (cell == null) {
      return false;
    }

    return (
      airlockCellIds.includes(id) ||
      (cell.parentBlockCellId &&
        airlockBlockCellIds.includes(cell.parentBlockCellId))
    );
  });
}
